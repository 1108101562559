.box-edit-form-modal-content{
    width: 50vw;
    padding: 20px 20px 10px 20px;

    .box-edit-form-modal-body{
        margin: 20px 0;

        .edit-type-option{
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            .material-icons-outlined{
                margin: 0 20px;
            }

            .edit-type-option-text{
                flex-grow: 1;
                font-size: 0.83em;
            }

            &:hover{
                background-color: #ddd;
                cursor: pointer;
            }
        }

        .reset-confirm-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px 0;

            p{
                font-size: 0.83em;
                padding: 10px 0;
            }
        }

        .box-edit-form-group{
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            margin: 10px 0;

            .box-edit-form-group-label{
                justify-self: end;
                flex: 4;
                margin-right: 20px;
            }

            .box-edit-form-group-input{
                flex: 6;
                display: flex;
                height: 100%;
                justify-content: center;
                flex-wrap: wrap;

                input{
                    flex: 1;
                    vertical-align: middle;
                    padding: 5px;
                    background-color: #fff;
                    border: 2px solid #ddd;
                    border-radius: 5px;

                    &.invalid{
                        border-color: red;
                    }

                    &.disabled{
                        background-color: #eee;
                        cursor: not-allowed;
                    }
                }

                input[type="number"]{
                    max-width: 100px;
                    text-align: center;
                }

                button{
                    border: none;
                    border-radius: 5px;
                    background-color: transparent;
                    height: 100%;
                    margin: auto 5px;
                    display: flex;
                    align-items: center;
                    color: #007aff;
                    cursor: pointer;
                    transition: transform 0.1s ease-out;

                    &:active{
                        transform: scale(0.95);
                    }

                    &:hover{
                        background-color: rgba($color: #007aff, $alpha: 0.25);
                    }

                    &.disabled{
                        color: grey;
                        cursor: not-allowed;
                        &:hover{
                            background-color: rgba($color: grey, $alpha: 0.25);
                        }
                    }
                }

                .box-edit-form-invalid-warning{
                    width: 100%;
                    text-align: center;
                    margin-top: 5px;
                    color: red;

                    &:empty{
                        display: none;
                    }
                }
            }
        }


        .box-edit-form-group-alarms{
            flex: 6;
            display: flex;
            flex-direction: column;
            align-items: center;

            input{
                flex: 1;
                vertical-align: middle;
                padding: 5px;
                background-color: #fff;
                border: 2px solid #ddd;
                border-radius: 5px;
            }

            input[type="number"]{
                max-width: 100px;
                text-align: center;
            }

            .add-alarm-button{
                width: auto;
                min-width: 70px;
                height: 35px;
                padding: 0 10px;
                background-color: transparent;
                border: 0px;
                border-radius: 5px;
                cursor: pointer;
                transition: all .2s ease-out 0s;
                color: #007aff;
                display: flex;
                align-items: center;

                .material-icons-outlined{
                    margin-right: 5px;
                }

                &:hover{
                    background-color: rgba($color: #007aff, $alpha: 0.25);
                }
            }
        }

        @media (max-width: 1000px) {
            .box-edit-form-group{
                flex-direction: column;
                align-items: stretch;

                input {
                    margin: 10px 0;
                }
            }
        }
    }

    .box-edit-form-modal-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;

        button{
            width: auto;
            min-width: 70px;
            height: 35px;
            padding: 0 10px;
            background-color: #FFF;
            border: 0px;
            border-radius: 5px;
            cursor: pointer;
            transition: all .2s ease-out 0s;

            &.button-remove{
                color: red;
                &:hover{
                    background-color: rgba($color: red, $alpha: 0.25);
                }
            }
            &.button-cancel, &.button-back{
                color: grey;
                &:hover{
                    background-color: rgba($color: grey, $alpha: 0.25);
                }
            }
            &.button-save{
                color: #007aff;
                &:hover{
                    background-color: rgba($color: #007aff, $alpha: 0.25);
                }
            }
        }
    }

    hr {
        color: grey;
        background: grey;
        border: 0;
        height: 2px;
        margin: 10px 0;
    }
}