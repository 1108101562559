@use '../../variables';

.settings-page{
    padding: 20px;

    // .box-container{
    //     width:60vw;
    //     height:60vh;
    //     background-color: rgb(182, 182, 182);
    //     margin-right: auto;
    //     margin-left: auto;
    //     display:flex;
    //     align-items: center;
    //     justify-content: center;
    //     border-radius: 0px;
    //     border-radius: 0px;
    //     background: #f1eeee;
    //     box-shadow:  45px 45px 90px #b7b5b5,
    //                  -45px -45px 90px #ffffff;

    //     .input-nickname{
    //         display: flex;
    //         flex-direction: column;
    //         margin: 2rem;
    //         padding:1rem;

    //         .input{
    //             width: 20rem;
    //             height: 2.2rem;
    //             padding: 12px 20px;
    //             margin: 8px 0;
    //             border-radius: 5px;
    //             border:1px,solid, rgb(180, 175, 175);
    //         }
    //         .button-container{
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;

    //             .update-button , .clear-button{
    //                 align-items: center;
    //                 appearance: button;
    //                 background-color: #0276FF;
    //                 border-radius: 8px;
    //                 border-style: none;
    //                 box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
    //                 box-sizing: border-box;
    //                 color: #fff;
    //                 cursor: pointer;
    //                 flex-shrink: 0;
    //                 font-family: "RM Neue", sans-serif;
    //                 font-size: 100%;
    //                 line-height: 1.15;
    //                 margin: 0.5rem;
    //                 padding: 10px 21px;
    //                 text-align: center;
    //                 text-transform: none;
    //                 transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out, box-shadow .13s ease-in-out;
    //                 user-select: none;
    //                 -webkit-user-select: none;
    //                 touch-action: manipulation;
    //                 transition: all .2s ease-in-out;
    //             }
    //             .clear-button{
    //                 background-color: #f44336;
    //             }
                
    //             .update-button , .clear-button{
    //                 width: 30%;
    //                 text-align: center;
    //             }
                
    //             .update-button:active{
    //                 background-color: #006AE8;
    //             }
                
    //             .update-button:hover{
    //                 transform: scale(1.08);
    //                 background-color: #1C84FF;
    //             }
    //             .clear-button:active{
    //                 background-color: #f44336;
    //             }
    //             .clear-button:hover{
    //                 transform: scale(1.08);
    //                 background-color: #f25b50;
    //             }
    //         }
    //     }
    //     .input-offset{

    //     .timezone-select{
    //             width: 25rem;
    //             height:2.5 rem;
    //             padding: 12px 20px;
    //             box-sizing: border-box;
    //     }
    //     .button-container{
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         .update-button {
    //             align-items: center;
    //             appearance: button;
    //             background-color: #0276FF;
    //             border-radius: 8px;
    //             border-style: none;
    //             box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
    //             box-sizing: border-box;
    //             color: #fff;
    //             cursor: pointer;
    //             flex-shrink: 0;
    //             font-family: "RM Neue", sans-serif;
    //             font-size: 100%;
    //             line-height: 1.15;
    //             margin: 0.5rem;
    //             padding: 10px 21px;
    //             text-align: center;
    //             text-transform: none;
    //             transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out, box-shadow .13s ease-in-out;
    //             user-select: none;
    //             -webkit-user-select: none;
    //             touch-action: manipulation;
    //             transition: all .2s ease-in-out;
    //         }
            
    //         .update-button {
    //             width: 30%;
    //             text-align: center;
    //         }
            
    //         .update-button:active{
    //             background-color: #006AE8;
    //         }
            
    //         .update-button:hover{
    //             transform: scale(1.08);
    //             background-color: #1C84FF;
    //         }
    //     }
          
    //     }


    // }

    .form-group{
        margin: 20px auto;
        @include variables.md {
            width: 60%;
        }

        .label{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text{
                font-size: 0.8em;
                font-weight: bold;
            }
            .visibility{
                display: flex;
                align-items: center;
                .material-icons-outlined{   
                    font-size: 1em;
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }
        .input-field{
            margin: 5px 0;
            padding: 10px 20px;
            width: 100%;
            border: 1px solid rgb(118,118,118);
            border-radius: 2px;

            &.input-name{
                &.empty{
                    border-color: red;
                }
            }
            
            &.input-gender{
                padding: 10px 0;
                border: none;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .radio-label-container{
                    display: grid;
                    grid-template-columns: 1em auto;
                    gap: 0.5em;
                    font-size: 0.8em;
                    cursor: pointer;
                }

                input[type="radio"] {
                    /* Remove most all native input styles */
                    appearance: none;
                    /* For iOS < 15 */
                    background-color: var(--form-background);
                    /* Not removed via appearance */
                    margin: 0;
                  
                    font: inherit;
                    width: 1.15em;
                    height: 1.15em;
                    border: 2px solid black;
                    border-radius: 50%;
                    transform: translateY(-0.075em);
                  
                    display: grid;
                    place-content: center;
                    cursor: pointer;

                    &::before{
                        content: "";
                        width: 0.65em;
                        height: 0.65em;
                        border-radius: 50%;
                        transform: scale(0);
                        transition: 120ms transform ease-in-out;
                        box-shadow: inset 1em 1em #1a73e8;
                    }
                    &:checked::before{
                        transform: scale(1);
                    }
                }
            }

            &.input-phone{
                padding: 0;

                input{
                    width: 100%;
                    height: auto;
                    line-height: normal;
                    padding: 10px 20px 10px 48px;
                    border: none;
                }
                .flag-dropdown {
                    border-left: none;
                    border-top: none;
                    border-bottom: none;
                }
            }
        }
    }

    .button-row{
        margin-top: 50px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner{
            margin: 0 auto;
        }
    }
}